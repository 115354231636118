@import 'values';

.memberList{
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
}

.memberListItem{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0;
    position: relative;
}

.memberListDropPlaceholder{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 1em;
    border: 1px solid transparent;
}

.memberListSubIcon{
    position:absolute;
    left: -1rem;
    top: 50%;
    transform: translate(-100%,-50%);
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.memberListRectangle{
    background: $color_lightgrey;
    display: flex;
    flex-direction: row;
    padding: 1.5rem;
    flex-grow: 1;
    border: 1px solid transparent;
}

.memberListRectangle.dragOver, .memberListDropPlaceholder.dragOver{
    border: 1px solid $color_lightblue;
}

.memberText{
    margin: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.memberListIcon{
    color: $color_black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
}
.memberListRemove{
    margin: 0 1rem 0 auto;
    cursor: pointer;
}

.addMember{
    height: 4rem;
    border: 0.15rem dashed $color_black;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.memberListAdd{
    font-size: 1.8rem;
}