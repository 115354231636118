@import 'values';

.memberTableSelector{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 0.75rem 1rem;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: 2.6rem;
    background: $color_tableHeadBorder;
    color: $color_white;
    box-shadow: 0 0 0.1rem 0 $color_black;
    z-index: 500;
}
.selector{
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin: 0 1rem;
}
.selectorLeft, .selectorRight{
    margin: 0 0.3rem;
    display: flex;
    justify-content: center;
    align-self: center;
    cursor: pointer;
}

.projectSelectionWrapper{
    margin-right: auto;
}
.projectSelectionWrapper label{
    margin-right: 1em;
}

.serverStatus{
    display: flex;
    margin-left: auto;
    flex-direction: row;
    box-sizing: border-box;
}
.connectionCheck{
    margin: 0 0.3rem;
    display: flex;
    justify-content: center;
    align-self: center;
}
.refresh{
    display: flex;
    justify-content: center;
    align-self: center;
    cursor: pointer;
    margin: 0 0.3rem;
    font-size: 1.5rem;
}
@media screen and(max-width: 800px){
    .memberTableSelector{
        padding: 0.75rem;
    }
    .serverStatus{
        display: none;
    }
    .projectSelectionWrapper{
        display: none;
    }
}
