@import "values";


.inputField{
    width: 100%;
    display: flex;
    flex-direction: row;
    background: $color_lightgrey;
    margin: 0;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
}

.inputField .inputTitle{
    flex: 0 1 25rem;
    margin-right: 1rem;
    font-size: 1rem;
    padding: 1rem;
}
.inputField.addInputField .inputTitle{
    flex: 0 1 27.2rem;
}

.addInputField select, .inputField .inputType{
    flex: 0 0 12rem;
    font-size: 1rem;
    margin-right: 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
@media screen and (max-width: 400px){
    .addInputField select, .inputField .inputType{
        flex: 0 0 6rem;
    }
}
.inputField .inputType{
    padding-left: 5px;
}

input[type="file"] {
    display: none;
}


.inputField button {
    border: none;
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    padding: 0;
    margin: 0 1rem;
}
.inputField button:hover{
    transform: scale(1.2);
    background: none;
    color: $color_black;
    font-weight: bolder;
}


.files{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.inputFile{
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color_lightgrey;
    padding: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.inputFile a{
    color: inherit;
} 

.inputFile .downloadLink{
    display: contents;
    color: inherit;
}
.addFile label{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.deleteFile{
    cursor: pointer;
}