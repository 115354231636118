@import '../values';

.memberWeekViewWrapper{
    width: 100%;
}
.listItem{
    width: 100%;
    height: 6em;
    box-sizing: border-box;
    background: $color_lightgrey;
    display: flex;
    flex-direction: row;
}
.listItem.special{
    height: 3.5rem;
}
.listItem:nth-of-type(2n){
    background: $color_white;
}
.listItemDate{
    flex: 0 0 auto;
    width: 3rem;
    height: 100%;
    border-right: 1px solid #999;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.listItemDate .day{
    font-size: 1rem;
}
.listItemDate .date, .listItemDate .month{
    font-size: 1rem;
}
.cellWrapper{
    flex: 1 1 auto;
}