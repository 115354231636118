@import 'values';

.contextMenu{
    position: absolute;
    background: $color_white;
    z-index: 1000;
    box-shadow: 0 0 0.1rem 0 $color_black;
}

.contextMenu ul{
    padding: 0;
    margin: 0;
    list-style: none;
}

.contextMenu ul li {
    font-size: 0.8rem;
    padding: 0.5rem 1.5rem;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    white-space: nowrap;
}
.contextMenu ul li:hover{
    background: $color_primary;
    color: $color_white;
} 