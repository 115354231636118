
/*Colors*/

$color_white: #F7F8F8;
$color_black: #1D1E23;
$color_lightgrey: #E7E7E7;
$color_primary: #2F2F38;
$border_color: #cccccc;

$color_lightblue: #454a69;
$color_lighterblue: #3d415c;
$color_darkblue: #2d3141;

$color_tableHeadLight: $color_lightblue;
$color_tableHeadDark: $color_lighterblue;
$color_tableHeadBorder: $color_darkblue;

$fontSize_medium: 0.9rem;

:export {
    colorWhite: $color_white;
    colorBlack: $color_black;
    colorPrimary: $color_primary;
  }