@import '../values';

.memberWeekViewSelector{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.6rem;
    background: $color_tableHeadBorder;
    color: $color_white;
    box-shadow: 0 0 0.1rem 0 $color_black;
    z-index: 500;
    position:sticky;
    top: 0;
}
.selector{
    cursor: pointer;
}
.selector > svg{
    vertical-align: middle;
}