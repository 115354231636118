@import 'values';
#root{
  height:100%;
}
html, body{
    width: 100vw;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial;
    user-select: none; 
    color: $color_black;
}
.titlebar > .window-appicon{
  width: 120px !important;
  background-position: center;
  background-size: auto 22px !important;
} 
.container-after-titlebar{ 
  height: calc(100vh - 30px);
  width: 100vw;
  position: relative;
}
.app{ 
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  overflow: hidden;
}
main{
  overflow: auto;
  background: $color_white; 
  position: relative;
  width: 100%;
  height: 100%;
}

@media screen and(max-width: 1000px){
  .app{ 
    flex-direction: column;
  }
}

@media screen and (max-width: 500px){
  html{
    font-size: 90%;
  }
}