@import 'values';

.projectList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    margin: 0;
}
.projectListItem{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 15rem;
    height: 15rem;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
    background: $color_lightgrey;
    position: relative;
}
.projectListText{
    margin: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    font-weight: bold;
}
.projectListCircle{
    margin: 0.8rem;
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    position: relative;
}
.projectListCircleSecondHalf{
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    right: 0;
    top: 0;
    clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
}
.projectListIcon{
    color: $color_black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    position: absolute;
}
.projectListEdit{
    cursor: pointer;
    bottom: 0.8rem;
    right: 0.8rem;
}
.projectListRemove{
    cursor: pointer;
    top: 0.8rem;
    right: 0.8rem;
}

.addProject{
    border: 0.15rem dashed $color_black;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: transparent;
}
.projectListAdd{
    font-size: 1.8rem;
}