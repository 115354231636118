@import 'values';


.colorSection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
}

.colorPreview{
    width: 15rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.normalColorPreview{
    display: flex;
    flex: 0 0 50%;
    justify-content: center;
    align-items: center;
}

.shadedColorPreview{
    display: flex;
    flex: 0 0 50%;
    justify-content: center;
    align-items: center;
}

.textColorPreview{
    position: relative;
    font-size: 1.1rem;
    z-index: 500;
}

.sketch-picker{
    box-shadow: none !important;
}