@import "values";

.tab {
  padding: 2rem;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
}

@media screen and (max-width: 400px) {
  .tab {
    padding: 2rem 1rem;
  }
}

h2 {
  text-align: left;
  color: $color_primary;
  width: 100%;
  margin: 0.6rem 0;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0.7rem 0;
  width: 100%;
}

.row.horizontal {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.checkboxWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.3rem;
}
.checkboxWrapper:last-of-type {
  margin-bottom: 0;
}
.checkboxWrapper label {
  font-size: $fontSize_medium;
  margin: 0;
}
.checkboxWrapper input[type="checkbox"] {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}
.checkboxWrapper input[type="checkbox"]:disabled {
  cursor: auto;
}

.inputText {
  margin: 0;
  font-size: 1rem;
  color: $color_primary;
  font-weight: bold;
  margin-bottom: 1rem;
}

input[type="textfield"],
input[type="password"],
select {
  cursor: text;
  padding: 1rem;
  border: none;
  background: $color_lightgrey;
  width: 100%;
  outline: none;
  font-size: $fontSize_medium;
  box-sizing: border-box;
}

select {
  cursor: pointer;
  padding-left: 0.8rem;
}

select.inline {
  padding: 0;
}

.select-search__select {
  position: absolute;
  background: $color_white;
  box-shadow: 0 0 0.1rem 0 $color_black;
  width: 100%;
}
.select-search {
  position: relative;
}
.select-search__options {
  list-style: none;
  padding: 0;
  margin: 0;
  color: $color_black;
}
.select-search__option {
  all: unset;
  box-sizing: border-box;
  min-width: 100%;
  font-size: 0.8rem;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
}
.select-search__value input {
  padding: 0.3rem 0.2rem;
}
.select-search__value input:focus {
  outline: none;
}

button {
  background: transparent;
  border: $color_primary 0.15rem solid;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 0;
}
button:hover {
  background: $color_primary;
  color: $color_white;
}
