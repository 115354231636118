@import 'values';

.mainMenu{
    flex: 0 0 18rem;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    background: $color_darkblue;
    overflow-y: auto;
}

.companyLogoWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 1rem;
    box-sizing: border-box;
}

.companyLogoWrapper img{
    width: 10rem;
    box-sizing: border-box;
}
.mainMenu ul{
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.menuItem{
    width: 100%;
    color: $color-white;
    font-size: 1.1rem;
    padding: 1.4rem 1rem;
    box-sizing: border-box;
    transition: background 0.1s ease-in-out;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 2rem;
}
.hamburgerMenu{
    display: none;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 600;
    width: 2rem;
    padding: 1.4rem
}
.hamburgerMenu .bar1, .hamburgerMenu .bar2, .hamburgerMenu .bar3 {
    position: absolute;
    width: 1.6rem;
    height: 0.14rem;
    background-color: #fff;
    margin: 0.4rem 0;
    transition: 0.4s;
    transform-origin: center;
}
.hamburgerMenu .bar1{
    transform: translateY(-0.5rem);
}
.hamburgerMenu .bar3{
    transform: translateY(0.5rem);
}
.hamburgerMenu.active .bar1 {
    -webkit-transform: rotate(-45deg) translate(0,0) ;
    transform: rotate(-45deg);
}
.hamburgerMenu.active .bar2 {
    opacity: 0;
}
.hamburgerMenu.active .bar3 {
    -webkit-transform: rotate(45deg) translate(0, 0) ;
    transform: rotate(45deg);
}
.menuItem > div{
    margin: 0 0.5em;
}
.menuIcon{
    display: flex;
    justify-content: center;
    align-items: center;
}
.menuItem:hover, .menuItem.active{
    background: $color_primary;
}

@media screen and (max-width: 1000px){
    .mainMenu{
        background: $color_darkblue;
        width: 100%;
        height: 4.5rem;
        flex: 0 0 auto;
        flex-direction: row;
        justify-content: space-between;
        overflow-y:visible;
    }
    .companyLogoWrapper{
        width: auto;
        padding: 0.5rem 1.4rem ;
    }
    .companyLogoWrapper img{
        width: auto;
        height: 3.5rem;
        cursor: pointer;
    }
    .hamburgerMenu{ 
        display: flex;
    }
    .mainMenu ul{
        position: absolute;
        z-index: 1000;
        top: 100%;
        left: 0;
        background: $color_darkblue;
        width: 100%;
        height: calc(100vh);
        flex-grow: 0;
        flex-direction: column;
        align-items: stretch;
        transition: transform 0.5s ease-in-out;
        transform: translateX(100%);
    }
    .mainMenu ul.active{
        z-index: 1000;
        transform: translateY(0);
    }
    .menuItem{
        width: auto;
        padding: 1.4rem 1rem;
        font-size: 1.3rem;
    }
    .menuItem:last-of-type{
        margin-top: 0;
    }
    .menuText{
        display: block;
        font-size: 1.1rem;
    }
}
