@import "values";

.memberTableWrapper {
  overflow: auto;
  height: calc(100% - 2.6rem);
  max-width: 100%;
  position: relative;
}
.memberTable {
  position: relative;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
}
.memberTable thead {
  position: -webkit-sticky; /* for Safari */
  position: sticky !important;
  top: 0;
  z-index: 120;
}
.memberTable thead th {
  padding: 0.5rem;
  z-index: 120;
  box-sizing: border-box;
  background: $color_tableHeadDark;
  color: $color_white;
  font-weight: normal;
  font-size: 1rem;
  height: 2.4rem;
  border: solid 1px $color_tableHeadBorder;
  border-left: none;
  border-top: none;
}
.memberTable thead .headerFirstRow th:nth-of-type(2n),
thead .headerSecondRow th:nth-of-type(16n + 1),
thead .headerSecondRow th:nth-of-type(16n + 2),
thead .headerSecondRow th:nth-of-type(16n + 3),
thead .headerSecondRow th:nth-of-type(16n + 4),
thead .headerSecondRow th:nth-of-type(16n + 5),
thead .headerSecondRow th:nth-of-type(16n + 6),
thead .headerSecondRow th:nth-of-type(16n + 7),
thead .headerSecondRow th:nth-of-type(16n + 8) {
  background: $color_tableHeadLight;
}

.rowHead {
  position: -webkit-sticky; /* for Safari */
  position: sticky !important;
  left: 0;
  padding: 0.5rem 0.8rem;
  z-index: 100;
  font-weight: bold;
  font-size: 0.95rem;
  min-width: 8rem;
  height: 4.5rem;
}
.memberTable thead .rowHead {
  z-index: 150;
  font-size: 1rem;
}
.memberTable thead .headSpecial {
  min-width: 5rem;
}
.memberTable thead .headDay {
  min-width: 9rem;
}
.memberTable thead .headDay > div {
  display: flex;
  flex-direction: column;
}
.memberTable thead .headDay > div > div {
  margin: 0.25rem 0;
}

.memberTable tbody tr th,
.memberTable tbody tr td {
  background: $color_white;
  box-sizing: border-box;
  padding: 0;
  border: solid 1px $border_color;
  border-left: none;
  border-top: none;
}
.memberTable tbody tr:nth-of-type(2n + 1) th,
.memberTable tbody tr:nth-of-type(2n + 1) td {
  background: $color_lightgrey;
}
.cellEntry {
  position: relative;
  box-sizing: border-box;
}
.cellEntry .entry {
  white-space: pre-wrap;
  user-select: none;
  display: block;
  background: transparent;
  font-family: Arial;
  box-sizing: border-box;
  padding: 0.25rem;
  font-size: 0.9rem;
  height: 4.5rem;
  width: 9rem;
  border: none;
  resize: none;
  cursor: cell;
  overflow-y: auto;
}
.cellEntry.selected {
  z-index: 10;
}
.cellEntry textarea.entry {
  user-select: auto;
  cursor: text !important;
}
.cellEntry textarea:focus {
  outline: 0;
}
.cellEntry.weekSpecial .entry {
  width: 5rem;
  text-align: center;
  padding-top: 1.75rem;
}

.selectionBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  background: $color_tableHeadLight;
  opacity: 0.3;
  pointer-events: none;
  transition-property: clip-path;
  transition-duration: 30ms;
  transition-timing-function: ease-in-out;
}

.projectSelection label {
  margin-right: 1rem;
}

@media screen and (max-width: 1000px) {
  .memberTable {
    margin: 0 auto;
  }
}
@media screen and (max-width: 800px) {
  .rowHead {
    min-width: 4rem;
  }
  .memberTable thead .rowHead {
    font-size: 1rem;
  }
}
@media screen and (max-height: 450px) {
  .memberTable thead th {
    position: relative !important;
  }
  .memberTable thead .headerSecondRow th {
    top: 0;
  }
  .memberTable thead .rowHead {
    top: auto;
  }
}
