.memberWeekViewCell{
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    white-space: normal;
    position: relative;
}
